import cn from "@/libs/cn";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import styles from "./styles.module.scss";

const isSSR = typeof window === "undefined";

interface CustomScroll {
  children: any;
  minHeight?: string | number;
  maxHeight?: string | number;
  forwardedRef?: any;
  className?: string;
  classNameContent?: string;
  classNameVerticalTrack?: string;
  classNameHorizontalTrack?: string;
  disableHorizontal?: boolean;
  onScrollEnd?: () => void;
  showTracksOnlyWhenActive?: boolean;
  showOnlyOnHover?: boolean;
  thinTrack?: boolean;
}

const CustomScroll = ({
  children,
  minHeight,
  maxHeight,
  forwardedRef,
  className,
  classNameContent,
  classNameVerticalTrack,
  classNameHorizontalTrack,
  disableHorizontal,
  onScrollEnd,
  showTracksOnlyWhenActive,
  showOnlyOnHover,
  thinTrack = false,
}: CustomScroll) => {
  const ref = useRef();
  const currentRef = forwardedRef || ref;
  const refHorizontalTrumb = useRef(null);
  const [sizeBox, setSizeBox] = useState({ height: 1, width: 1 });
  const [scrollWidth, setScrollWidth] = useState(1);
  const [scrollHeight, setScrollHeight] = useState(1);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const isActiveHorizontalScroll = scrollWidth > sizeBox.width + 3;
  const isActiveVerticalScroll = scrollHeight > sizeBox.height + 3;

  const maxDX = useMemo(() => scrollWidth - sizeBox.width, [scrollWidth, sizeBox.width]);
  const maxDY = useMemo(() => scrollHeight - sizeBox.height, [scrollHeight, sizeBox.height]);

  const [coordinates, setStartCoordinates] = useState<any>(null);

  const setInitValues = useCallback((el: any) => {
    const { scrollWidth, scrollHeight, scrollLeft, scrollTop } = el;
    setScrollWidth(scrollWidth);
    setScrollHeight(scrollHeight);
    setScrollLeft(scrollLeft);
    setScrollTop(scrollTop);
  }, []);

  const resizeObserver = useMemo(() => {
    if (isSSR) {
      return;
    }

    return new ResizeObserver((entries) => {
      if (currentRef?.current) {
        setInitValues(currentRef.current);
        const rect = currentRef.current.getBoundingClientRect();
        setSizeBox(rect);
      }
    });
  }, [currentRef, setInitValues]);

  const onScroll = useCallback(
    (event: any) => {
      setInitValues(event.target);

      if (onScrollEnd) {
        const { height } = event.target.getBoundingClientRect();
        const { scrollHeight, scrollTop } = event.target;
        if (height + scrollTop + 5 > scrollHeight) {
          onScrollEnd();
        }
      }
    },
    [onScrollEnd, setInitValues]
  );

  useEffect(() => {
    if (currentRef?.current) {
      if (!isSSR) {
        resizeObserver?.observe(currentRef.current);
      }
    }
  }, [currentRef, resizeObserver]);

  const widthHorizontalTrumb = useMemo(
    () => sizeBox.width * (sizeBox.width / scrollWidth),
    [scrollWidth, sizeBox.width]
  );

  const leftHorizontalTrumb = useMemo(
    () => scrollLeft * (sizeBox.width / scrollWidth),
    [scrollLeft, scrollWidth, sizeBox.width]
  );

  const heightVerticalTrumb = useMemo(
    () => sizeBox.height * (sizeBox.height / scrollHeight),
    [scrollHeight, sizeBox.height]
  );

  const topVerticalTrumb = useMemo(
    () => scrollTop * (sizeBox.height / scrollHeight),
    [scrollHeight, scrollTop, sizeBox.height]
  );

  const onMouseMove = useCallback(
    (ev: any) => {
      if (!currentRef?.current) {
        return;
      }

      if (coordinates?.clientX) {
        let dx = coordinates.scrollLeft - (coordinates.clientX - ev.clientX) * (scrollWidth / sizeBox.width);

        if (dx < 0) {
          dx = 0;
        } else if (dx > maxDX) {
          dx = maxDX;
        }

        currentRef.current.scrollLeft = dx;
        setScrollLeft(dx);
      }

      if (coordinates?.clientY) {
        let dy = coordinates.scrollTop + (ev.clientY - coordinates.clientY) * (scrollHeight / sizeBox.height);

        if (dy < 0) {
          dy = 0;
        } else if (dy > maxDY) {
          dy = maxDY;
        }

        currentRef.current.scrollTop = dy;
        setScrollTop(dy);
      }
    },
    [
      coordinates?.clientX,
      coordinates?.clientY,
      coordinates?.scrollLeft,
      coordinates?.scrollTop,
      currentRef,
      maxDX,
      maxDY,
      scrollHeight,
      scrollWidth,
      sizeBox.height,
      sizeBox.width,
    ]
  );

  const onMouseUp = useCallback(
    (ev: any) => {
      ev.stopPropagation();
      ev.preventDefault();

      document.removeEventListener("mousemove", onMouseMove, false);
      document.removeEventListener("mouseup", onMouseUp, false);
      setStartCoordinates(null);
    },
    [onMouseMove]
  );

  const onPressHorizontalTrumb = useCallback(
    (ev: any) => {
      if (!currentRef?.current) {
        return;
      }
      ev.stopPropagation();
      ev.preventDefault();

      setStartCoordinates({
        scrollLeft: currentRef.current.scrollLeft,
        clientX: ev.clientX,
      });
      document.addEventListener("mousemove", onMouseMove, false);
      document.addEventListener("mouseup", onMouseUp, false);
    },
    [currentRef, onMouseMove, onMouseUp]
  );

  const onPressVerticalTrumb = useCallback(
    (ev: any) => {
      if (!currentRef?.current) {
        return;
      }
      ev.stopPropagation();
      ev.preventDefault();

      setStartCoordinates({
        clientY: ev.clientY,
        scrollTop: currentRef.current.scrollTop,
      });

      document.addEventListener("mousemove", onMouseMove, false);
      document.addEventListener("mouseup", onMouseUp, false);
    },
    [currentRef, onMouseMove, onMouseUp]
  );

  useEffect(() => {
    if (coordinates === null) {
      document.removeEventListener("mousemove", onMouseMove, false);
      document.removeEventListener("mouseup", onMouseUp, false);
    } else {
      document.addEventListener("mousemove", onMouseMove, false);
      document.addEventListener("mouseup", onMouseUp, false);
    }

    return () => {
      document.removeEventListener("mousemove", onMouseMove, false);
      document.removeEventListener("mouseup", onMouseUp, false);
    };
  }, [coordinates, onMouseMove, onMouseUp]);

  const onStopEvent = useCallback((ev: any) => {
    ev.stopPropagation();
    ev.preventDefault();
  }, []);

  return (
    <div className={cn(styles.CustomScroll, showOnlyOnHover && styles.showOnlyOnHover, thinTrack && styles.thin, className)}>
      <div
        ref={forwardedRef || ref}
        className={cn(styles.content, classNameContent)}
        onScroll={onScroll}
        style={{
          maxHeight,
          minHeight,
          overflowX: disableHorizontal ? "hidden" : undefined,
        }}
      >
        {children}
      </div>
      {(!showTracksOnlyWhenActive || isActiveVerticalScroll) && (
        <div className={cn(styles.verticalTrack, classNameVerticalTrack)} onClick={onStopEvent}>
          {isActiveVerticalScroll && (
            <div
              className={styles.verticalTrumb}
              style={{ height: heightVerticalTrumb, top: topVerticalTrumb }}
              onMouseDown={onPressVerticalTrumb}
            ></div>
          )}
        </div>
      )}
      {(!showTracksOnlyWhenActive || isActiveHorizontalScroll && !disableHorizontal) && (
        <div className={cn(styles.horizontalTrack, classNameHorizontalTrack)} onClick={onStopEvent}>
          {isActiveHorizontalScroll && !disableHorizontal && (
            <div
              ref={refHorizontalTrumb}
              className={styles.horizontalTrumb}
              style={{ width: widthHorizontalTrumb, left: leftHorizontalTrumb }}
              onMouseDown={onPressHorizontalTrumb}
            ></div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.forwardRef((props: CustomScroll, ref) => <CustomScroll {...props} forwardedRef={ref} />);
