"use client";
import cn from "@/libs/cn";
import CardProduct from "@/modules/home/components/CardProduct";
import { useCategoryContext } from "../PageProductsListContext";
import useClientContext from "@/context/ClientContext";

import styles from "./styles.module.scss";
import Image from "@/components/basic/Image";
import routesPublic from "../../constants/routesPublic";
interface LazyProductsListProps {
  lazyAfterIndex: number;
  classNameItem?: string;
  classNameItemDirty?: string;
}

const LazyProductsList = (props: LazyProductsListProps) => {
  const { lazyAfterIndex, classNameItem, classNameItemDirty } = props;
  const { translate, currency, locale } = useClientContext();
  const { list, dirty } = useCategoryContext();

  return (
    <>
      {list.length ? (
        list.map((item, idx) => (
          <div key={item.id} className={cn(classNameItem, dirty && classNameItemDirty)}>
            <CardProduct {...item} imageLoading={idx < lazyAfterIndex ? "eager" : "lazy"}/>
          </div>
        ))
      ) : (
        <div className={styles.notFound}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src="/assets/not-found.webp" alt="not found" />
          <div>{translate("No products found")}</div>
        </div>
      )}
    </>
  );
};

export default LazyProductsList;
