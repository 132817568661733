import cn from "@/libs/cn";
import { QUERY_SEARCH_PARAM_NAME } from "@/constants/common";
import Image from "@/components/basic/Image";
import Link from "@/components/basic/Link";
import routesPublic from "@/modules/public/constants/routesPublic";
import { useDataPublicCategoryBreadcrumbs } from "@/modules/public/hooks/useData";
import PublicIcons from "@/components/basic/PublicIcon";

import styles from "./styles.module.scss";

interface LinkCategoryProps {
  isCurrent?: boolean;
  className?: string;
  category: {
    id: number;
    parent: number | null;
    icon: string | null;
    name: string;
    slug: string;
    description: string | null;
    has_children: boolean;
  };
  q?: string;
}

const LinkCategory = (props: LinkCategoryProps) => {
  const { isCurrent, category, q } = props;
  const { id, parent, icon, name, slug, description } = category;
  const isTop = parent === null;

  const { data } = useDataPublicCategoryBreadcrumbs(id);

  const slugs = data.map((i) => i.slug).join("/");
  const href = `${routesPublic.category()}${slugs}/${q ? `?${QUERY_SEARCH_PARAM_NAME}=${q}` : ""}`;

  return (
    <Link
      key={id}
      className={cn(styles.LinkCategory, styles.item, isCurrent && styles.active)}
      href={href}
      title={description || ""}
    >
      {isTop && <Image src={icon || ""} alt="" width={24} height={24} className={styles.icon} />}
      <span className={styles.name}>{name}</span>
      <div style={{ flex: 1 }} />
      {category.has_children && <PublicIcons name="arrowRight" width={24} height={24} />}
    </Link>
  );
};

export default LinkCategory;
